export const environment = {
  production: !!+`${process.env['PRODUCTION']}`,
  backend: {
    host: `${process.env['BACKEND_HOST']}`,
  },
  sentry: {
    enabled: !!+`${process.env['SENTRY_ENABLED']}`,
    dsn: `${process.env['SENTRY_DSN_MOBILE']}`,
    environment: `${process.env['SENTRY_ENVIRONMENT']}`,
    tracingOrigins: `${process.env['SENTRY_TRACING_ORIGINS']}`.split(','),
  },
  application: 'mobile',
  releaseVersion: '1.554.1',
  talkJsConfig: {
    talkjsAppId: `${process.env['TALKJS_APP_ID']}`,
    role: `${process.env['TALKJS_ROLE']}`,
  },
  launchDarklyClientId: `${process.env['LAUNCHDARKLY_CLIENT_ID']}`,
  productFruitsWorkspaceCode: `${process.env['PRODUCT_FRUITS_WORKSPACE_CODE']}`,
  matomo: {
    disabled: !!+`${process.env['MATOMO_DISABLED']}`,
    trackerUrl: `${process.env['MATOMO_TRACKER_URL']}`,
    siteId: `${process.env['MATOMO_SITE_ID']}`,
  },
  privacyUrl: `${process.env['PRIVACY_URL']}`,
  imprintUrl: `${process.env['IMPRINT_URL']}`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
