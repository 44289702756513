import { Injectable } from '@angular/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { GeoLocation } from '@wilson/interfaces';
import { LocationNamePipe } from '@wilson/pipes';
import { map, Observable, switchMap } from 'rxjs';
import Fuse from 'fuse.js';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationFilterService {
  constructor(
    private readonly locationNamePipe: LocationNamePipe,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  public filterGeoLocations(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    maxResults = 25,
  ): Observable<Pick<GeoLocation, 'name' | 'locationCode' | 'id'>[]> {
    return this.featureFlagPurePipe
      .transform('portal-mobile-fuzzy-location-search')
      .pipe(
        switchMap((isFuseSearchEnabled: boolean) => {
          if (isFuseSearchEnabled) {
            return this.fuseSearch(searchKey, searchTargets, maxResults);
          } else {
            return this.normalSearch(searchKey, searchTargets, maxResults);
          }
        }),
      );
  }

  private normalSearch(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    maxResults: number,
  ): Observable<Pick<GeoLocation, 'name' | 'locationCode' | 'id'>[]> {
    searchKey = searchKey.trim();
    return searchTargets.pipe(
      map((targets) => {
        return targets
          .filter(
            (location) =>
              location.name
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()) ||
              (location.name + location.locationCode)
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()) ||
              (location.locationCode &&
                location.locationCode
                  .toLocaleLowerCase()
                  .includes(searchKey.toLocaleLowerCase())) ||
              this.locationNamePipe
                .transform(location)
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()),
          )
          .slice(0, maxResults);
      }),
    );
  }

  private fuseSearch(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    maxResults: number,
  ): Observable<Pick<GeoLocation, 'name' | 'locationCode' | 'id'>[]> {
    return searchTargets.pipe(
      map((targets) => {
        const fuse = new Fuse(targets, {
          keys: ['name', 'locationCode'],
          threshold: 0.6,
          includeScore: true,
          includeMatches: true,
          ignoreLocation: true,
        });

        const results = fuse
          .search(searchKey)
          .slice(0, maxResults)
          .map((result) => result.item);

        return results;
      }),
    );
  }
}
