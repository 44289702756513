/// <reference types="@types/googlemaps" />
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Address,
  GeoLocation,
  LocationCategoryIdsMap,
} from '@wilson/interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'wilson-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressAutocompleteComponent),
      multi: true,
    },
  ],
})
export class AddressAutocompleteComponent implements ControlValueAccessor {
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() geoLocations: GeoLocation[] = [];
  @Input() locationCategoryId!: string;
  @Input() invalid = false;
  @Input() warning = false;
  @Input() isAddress = false;
  @Input() placeholder = 'general.addressPlaceholder';
  @Input() set selectedLocation(location: GeoLocation | null | undefined) {
    if (location) {
      location.name
        ? (this._selectedLocation = location)
        : this.chooseOption(location);
      this.formatedLocation$.next(
        location?.name || location?.description || '',
      );
    }
  }
  get selectedLocation(): GeoLocation | null | undefined {
    return this._selectedLocation;
  }

  @Output() selectLocation = new EventEmitter<GeoLocation | null>();
  @Output() changeAddressEmitter = new EventEmitter<string>();
  @Output() touched = new EventEmitter<boolean>();

  private _selectedLocation: GeoLocation | null = null;
  public locationOptions$!: Observable<GeoLocation[]>;
  public formatedLocation$ = new BehaviorSubject<string>('');

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  search = (text$: Observable<string>) => {
    this.locationOptions$ = text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => (term.length < 3 ? [] : this.addressEvent(term))),
    );
  };

  searchLocation(term: string) {
    this.search(of(term));
    if (!term && this.isAddress) this.changeAddressEmitter.emit(term);
  }

  public addressEvent(term: string) {
    return new Promise<google.maps.places.QueryAutocompletePrediction[]>(
      (resolve) => {
        let optionInput = [];
        const displaySuggestions = (
          predictions: google.maps.places.QueryAutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (
            status !== google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            return;
          }
          optionInput = predictions;
          resolve(optionInput);
          this.cdRef.detectChanges();
        };
        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: term }, displaySuggestions);
      },
    );
  }

  public chooseOption(
    location: GeoLocation | google.maps.places.QueryAutocompletePrediction,
  ): void {
    if ((location as google.maps.places.QueryAutocompletePrediction).place_id) {
      const request = {
        placeId: (location as google.maps.places.QueryAutocompletePrediction)
          .place_id,
        fields: [
          'name',
          'address_component',
          'type',
          'formatted_address',
          'geometry',
          'place_id',
          'reference',
          'url',
          'vicinity',
          'website',
        ],
      };
      const service = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails(request, (place) => {
        this.transformLocationObjectFromGoogleMaps(place);
      });
    } else {
      this.transformLocationObjectFromGoogleMaps(location);
    }
  }

  async transformLocationObjectFromGoogleMaps(event) {
    let lat = event.geometry.location.lat();
    let lng = event.geometry.location.lng();

    lat = typeof lat === 'number' ? lat : event.geometry.location.lat;
    lng = typeof lng === 'number' ? lng : event.geometry.location.lng;

    const address: Address = {
      street: ' ',
      postCode: ' ',
      city: ' ',
      state: ' ',
      country: ' ',
    };

    let street = null;
    let streetNumber = null;

    for (const data of event.address_components) {
      if (data && data.types[0] === 'route') {
        street = data.long_name;
      }
      if (data && data.types[0] === 'street_number') {
        streetNumber = data.long_name;
      }
      if (data && data.types[0] === 'postal_code') {
        address.postCode = data.long_name;
      }
      if (data && data.types[0] === 'locality') {
        address.city = data.long_name;
      }
      if (data && data.types[0] === 'administrative_area_level_1') {
        address.state = data.long_name;
      }
      if (data && data.types[0] === 'country') {
        address.country = data.long_name;
      }
    }

    if (streetNumber && street) {
      address.street = `${street} ${streetNumber}`;
    } else if (street) {
      address.street = street;
    }

    address.street = address.street ? address.street : ' ';

    const geoLocation: GeoLocation = {
      name: event.formatted_address,
      locationCode: null,
      latitude: lat,
      longitude: lng,
      locationCategoryId: LocationCategoryIdsMap.Address,
      addressId: null,
      address,
    };

    this.formatedLocation$.next(geoLocation?.name);
    this._selectedLocation = geoLocation;
    this.propagateChange(geoLocation);
    this.selectLocation.emit(geoLocation);
    this.cdRef.detectChanges();
  }

  propagateChange = (location: GeoLocation) => {
    return location;
  }; // needed for form controle registration

  writeValue(value) {
    // needed for form controle registration
    if (value) {
      if (this.isAddress) {
        this.selectedLocation = this.writeAddress(value);
        this.cdRef.detectChanges();
        return;
      }
      this.selectedLocation = value;
    }
  }
  writeAddress(value: Address) {
    if (value) {
      if (this.isAddress) {
        return {
          name: `${value?.street}, ${value?.city}, ${value?.state}, ${value?.country}`,
          addressId: value.id,
        } as unknown as GeoLocation;
      }
    }
  }

  registerOnChange(fn) {
    // needed for form controle registration
    this.propagateChange = fn;
  }

  registerOnTouched() {
    return;
  } // needed for form controle registration

  public changeAddress(address: string) {
    if (!address) this.changeAddressEmitter.emit(address);
  }

  setSelectedLocation(location: GeoLocation) {
    if (typeof location === 'object') {
      this.selectedLocation = location;
    } else if (!location) {
      this.selectLocation.emit(null);
    }
  }
}
